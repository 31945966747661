import React from 'react';
import { Modal } from 'antd';
import NewReportModal from './NewReportModal';

const ReportsModal = ({ isModalVisible, setIsModalVisible }: any) => {
  return (
    <div style={{ color: "white" }}>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        width="100vw"
        bodyStyle={{ height: '100vh', padding: 0 }}
        style={{ top: 0 }}
      >
        <NewReportModal setIsModalVisible={setIsModalVisible} />
      </Modal>
    </div>
  );
};

export default ReportsModal;
