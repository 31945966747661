import React, { useEffect, useState } from 'react';
import { Button, message, Select, Tabs } from 'antd';
import ChannelAveragePower from '../Graphs/ChannelAveragePower';
import { Utilities } from '../../Utilities';
import ChannelPeakPower from '../Graphs/ChannelPeakPower';
import Occupancy from '../Graphs/Occupancy';
import OccupancyDetail from '../Graphs/OccupanyDetail';
import Navbar from '../Navbar';
import { ManualSpinnerLoader } from '../../ManualSpinnerLoader';
import ReportsModal from './Modal/ReportsModal';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const ReportsDisplay: React.FC = () => {

  const storageData: any = localStorage.getItem('StorageData');
  const parsedStorageData = JSON.parse(storageData);
  const initialReportKeyFromLocalStorage: any = localStorage.getItem('InitialReportKey');
  const parsedInitialReportKey: string = JSON.parse(initialReportKeyFromLocalStorage);
  const utils = new Utilities();
  const [activeKey, setActiveKey] = useState<string>(parsedInitialReportKey);
  const [allReports, setAllReports] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);


  useEffect(() => {
    loadChopData();
  }, []);

  const loadChopData = async () => {
    setIsLoading(true);
    try {
      const data: any = await utils.loadChopDataFromIndexedDB();
      const indexDbData = data?.fetchedData;

      if (indexDbData) {
        const reports = [
          {
            key: '1',
            label: 'Channel Average Power',
            children: <ChannelAveragePower chopData={indexDbData} />,
          },
          {
            key: '2',
            label: 'Channel Peak Power',
            children: <ChannelPeakPower chopData={indexDbData} />,
          },
          {
            key: '3',
            label: 'Occupancy',
            children: (
              <>
                <Occupancy chopData={indexDbData} />
                <OccupancyDetail chopData={indexDbData} />
              </>
            ),
          },
        ];

        setAllReports(reports);
        setItems([reports[parseInt(parsedInitialReportKey) - 1]]);
      }


    } catch (e) {
      console.error('Error fetching data reports display:', e);
      message.error('Failed to fetch data');
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const addTab = (report: any) => {
    const newPanes = [...items, report];
    setItems(newPanes);
    setActiveKey(report.key);
  };

  const remove = (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onDropdownChange = (value: string) => {
    const selectedReport = allReports.find((report) => report.label === value);
    if (selectedReport) {
      addTab(selectedReport);
    }
  };

  return (
    <>
      <div className='h-[10vh]'>
        <Navbar page="reports" />
      </div>

      <div className="min-h-[90vh] h-fit bg-black flex flex-col p-5 space-y-5 text-white">
        {isLoading === true ? (

          <ManualSpinnerLoader componentName={"Reports"} />

        ) : (
          <>


            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Select
                style={{ width: '15%' }}
                placeholder="Select Existing Report"
                allowClear={true}
                options={allReports.map((report) => ({
                  label: report.label,
                  value: report.label,
                  disabled: items.some((item) => item.key === report.key), // Gray out already opened tabs
                }))}
                onChange={onDropdownChange}
              />


              <Button onClick={() => setIsModalVisible(true)}>Create New Report</Button>

            </div>


            <div className="bg-gray-800 text-white p-3 rounded-lg">
              <div className="flex flex-row">
                <p style={{ fontSize: "14px" }}><strong>Site Name:</strong> {parsedStorageData?.siteName}</p>
                <p style={{ fontSize: "14px", marginLeft: "20px" }}><strong>Node Name:</strong> {parsedStorageData?.nodeName}</p>
                <p style={{ fontSize: "14px", marginLeft: "20px" }}><strong>REC ID:</strong> {parsedStorageData?.jobRecId}</p>
              </div>
              <div className="flex flex-row">
                <p style={{ fontSize: "14px" }}><strong>Start Time:</strong> {parsedStorageData.startTime}</p>
                <p style={{ fontSize: "14px", marginLeft: "20px" }}><strong>End Time:</strong> {parsedStorageData.endTime}</p>
              </div>
            </div>

            <div className="flex-grow">
              <Tabs
                type="editable-card"
                hideAdd
                onChange={onChange}
                activeKey={activeKey}
                onEdit={remove}
                items={items}
              />

            </div>
          </>
        )}
      </div>


      <ReportsModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />

    </>
  );
};

export default ReportsDisplay;
