import axios from "axios";
import { message } from "antd";
import { Utilities } from "./Utilities";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:8081";
const utils = new Utilities();

export const fetchToken = async (
  email: string,
  password: string
): Promise<{ message: string; status: number; token?: string }> => {
  try {
    const bodyData = { email, password };
    const response = await axios.post(`${BASE_URL}/users/login`, bodyData);

    return {
      message: response.data.message,
      status: response.status,
      token: response.data.token,
    };
  } catch (e: any) {
    console.error("Error fetching token: ", e);
    return { message: e.response.data.message, status: e.response.status };
  }
};
export const resetPassword = async (
  value: Record<string, string>
): Promise<{ message: string; status: number }> => {
  try {
    const response = await axios.post(
      `${BASE_URL}/users/reset-password`,
      value
    );

    return { message: response?.data?.message, status: response?.status };
  } catch (e: any) {
    console.error("Error fetching token: ", e);
    return { message: e.response.data.message, status: e.response.status };
  }
};

const getToken = () => {
  const token = localStorage.getItem("token") ?? "";
  const decodedToken = utils.jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

  // If the token is expired, log the user out
  if (decodedToken.exp && decodedToken.exp <= currentTime) {
    localStorage.removeItem("token");
    window.location.href = "/"; // Redirect to login page
  }

  return token;
};

// Setting up an axios instance
const apiClient = axios.create();

// Adding request interceptor to show loader
apiClient.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      // Attach token to request headers
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    // Show the spinner loader
    const event = new Event("showSpinner");
    window.dispatchEvent(event);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Adding response interceptor to hide loader
apiClient.interceptors.response.use(
  (response) => {
    // Hide the spinner loader after 2 seconds
    setTimeout(() => {
      const event = new Event("hideSpinner");
      window.dispatchEvent(event);
    }, 1000); // 2 seconds delay

    return response;
  },
  (error) => {
    // Hide the spinner loader even if there is an error
    setTimeout(() => {
      const event = new Event("hideSpinner");
      window.dispatchEvent(event);
    }, 1000); // 2 seconds delay

    return Promise.reject(error);
  }
);

export const fetchNodes = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/nodes/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (e) {
    console.error("Error fetching nodes data: ", e);
    return [];
  }
};

export const fetchNodesBySiteId = async (id: number) => {
  try {
    const response = await apiClient.get(`${BASE_URL}/nodes/site/${id}`);
    return response.data;
  } catch (e) {
    console.error("Error fetching nodes data: ", e);
    return [];
  }
};

export const fetchNodesByUserIdAndSiteId = async (
  userId: number,
  siteId: number
) => {
  try {
    const response = await apiClient.get(
      `${BASE_URL}/user-nodes/user/${userId}/site/${siteId}`
    );
    const nodesData = response.data.map((item: any) => item.Node);
    return nodesData;
  } catch (e) {
    console.error("Error fetching nodes data: ", e);
    return [];
  }
};

export const fetchSites = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/sites/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (e) {
    console.error("Error fetching nodes data: ", e);
    return [];
  }
};
export const fetchSitesByUserId = async (id?: Number) => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/user-sites/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.map((item: any) => item.Site);
  } catch (e) {
    console.error("Error fetching nodes data: ", e);
    return [];
  }
};

export const getCollections = async (
  limit: number,
  start: number
): Promise<{ message?: string; status: number; data?: any }> => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/icc/table`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit: limit,
        start: start,
      },
    });

    return { data: response.data, status: response.status };
  } catch (e: any) {
    console.error("Error while getting data: ", e);
    return { message: e.response.data.message, status: e.response.status };
  }
};

export const getChopData = async (
  job_recid: number,
  min_create_date: string,
  max_create_date: string
) => {
  try {
    const token = getToken();
    const response = await axios.get(
      `${BASE_URL}/icc/api/chop_output/${job_recid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          collect_start: min_create_date,
          collect_end: max_create_date,
        },
      }
    );

    return response.data.data;
  } catch (e) {
    console.error("Error while getting data: ", e);
    return null;
  }
};

export const getPsdData = async (params: any) => {
  try {
    const token = getToken();
    const response = await axios.get(
      `${BASE_URL}/icc/api/get_average_psd_data_mask_threshold`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          count: params.count,
          start_time: params.start_time,
          end_time: params.end_time,
          mask_start_time: params.mask_start_time,
          mask_end_time: params.mask_end_time,
          threshold: params.threshold,
          job_recid: params.job_recid,
          operation: params.operation,
        },
      }
    );

    return response.data[params.job_recid];
  } catch (e) {
    console.error("Error while getting data: ", e);
    return null;
  }
};

export const getPsdRangeData = async (jobRecId: number) => {
  try {
    const response = await apiClient.get(
      `${BASE_URL}/icc/api/get_psd_data_range`,
      {
        params: {
          job_recid: jobRecId,
        },
      }
    );

    return response.data[jobRecId];
  } catch (e) {
    console.error("Error while getting data: ", e);
    return null;
  }
};

export const getJobCVChopData = async (jobId: number) => {
  try {
    const response = await apiClient.get(
      `${BASE_URL}/icc/api/job_cvs/${jobId}`
    );

    return response.data.data;
  } catch (e) {
    console.error("Error while getting job CV data: ", e);
    return null;
  }
};

export const getChopSliceInfo = async (
  jobId: number,
  bandwidths: number[],
  frequencies: number[]
) => {
  try {
    const cv_bandwidths = bandwidths.join(","); // Joining the bandwidths as a comma-separated string
    const cv_center_freqs = frequencies.join(","); // Joining the frequencies as a comma-separated string

    const response = await apiClient.get(
      `${BASE_URL}/icc/api/slice_info/${jobId}`,
      {
        params: {
          cv_bandwidths: cv_bandwidths,
          cv_center_freqs: cv_center_freqs,
        },
      }
    );

    // Return the first element of the data
    return response.data[0];
  } catch (e) {
    console.error("Error while fetching slice info: ", e);
    return null;
  }
};

export const getOrganizations = async () => {
  try {
    const response = await apiClient.get(`${BASE_URL}/organizations`);
    return response.data;
  } catch (e) {
    console.error("Error while getting organizations data: ", e);
    return null;
  }
};

export const getAllOrganizations = async () => {
  try {
    const response = await apiClient.get(`${BASE_URL}/organizations/all`);
    return response.data;
  } catch (e) {
    console.error("Error while getting organizations data: ", e);
    return null;
  }
};

export const createOrganization = async (organizationData: {
  name: string;
  isActive: boolean;
}): Promise<{ message: string; status: number }> => {
  try {
    const response = await apiClient.post(
      `${BASE_URL}/organizations`,
      organizationData
    );

    // Return the created organization data
    return { message: response.data.message, status: response.status };
  } catch (e: any) {
    if (e.response.status === 409) {
      return {
        message: "Organization already exists",
        status: e.response.status,
      };
    }
    return { message: e.message, status: e.response.status };
  }
};

export const updateOrganization = async (
  id: number,
  organizationData: { name?: string; isActive?: boolean }
): Promise<{ message: string; status: number }> => {
  try {
    const response = await apiClient.put(
      `${BASE_URL}/organizations/${id}`,
      organizationData
    );

    // Return the success message from the response
    return { message: response.data.message, status: response.status };
  } catch (e: any) {
    if (e.response.status === 409) {
      return {
        message: "Organization already exists",
        status: e.response.status,
      };
    }
    return { message: e.message, status: e.response.status };
  }
};

export const getOrganizationSitesBySiteId = async (siteId: number) => {
  try {
    const response = await apiClient.get(
      `${BASE_URL}/organizationsites/site/${siteId}`
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const deleteOrganizationSitesBySiteId = async (siteId: number) => {
  try {
    const response = await apiClient.delete(
      `${BASE_URL}/organizationsites/site/${siteId}`
    );

    // Return the response message
    return response.status;
  } catch (e) {
    console.error("Error while deleting organization: ", e);
    return null;
  }
};

export const fetchSitesByOrganizationId = async (organizationId: number) => {
  try {
    const response = await apiClient.get(
      `${BASE_URL}/organizationsites/organization/${organizationId}`
    );
    return response.data;
  } catch (e) {
    console.error("Error while getting Sites data: ", e);
    return null;
  }
};

export const createSite = async (siteData: {
  name: string;
  organizationId: string;
  location: string;
  isActive: boolean;
}): Promise<{ createdSite?: any; message: string; status: number }> => {
  try {
    const response = await apiClient.post(`${BASE_URL}/sites`, siteData);

    // Return the created site data
    return {
      createdSite: response.data.site,
      message: response.data.message,
      status: response.status,
    };
  } catch (e: any) {
    if (e.response.status === 409) {
      return { message: "Site already exists", status: e.response.status };
    }
    return { message: e.message, status: e.response.status };
  }
};

export const linkOrganizationSite = async (organizationSiteData: {
  organizationId: number;
  siteId: number;
}) => {
  try {
    const response = await apiClient.post(
      `${BASE_URL}/organizationsites`,
      organizationSiteData
    );

    // Return the created organization-site link data
    return response.data;
  } catch (e: any) {
    return null; // Return null if there was an error
  }
};

export const updateSite = async (
  siteId: number,
  siteData: { name?: string; location?: string; isActive?: boolean }
): Promise<{ message: string; status: number }> => {
  try {
    const response = await apiClient.put(
      `${BASE_URL}/sites/${siteId}`,
      siteData
    );

    // Return the message from the response
    return { message: response.data.message, status: response.status };
  } catch (e: any) {
    if (e.response.status === 409) {
      return { message: "Site already exists", status: e.response.status };
    }
    return { message: e.message, status: e.response.status };
  }
};

export const createNode = async (nodeData: {
  name: string;
  siteId: number;
  alias: string | null;
  isActive: boolean;
}): Promise<{ message: string; status: number }> => {
  try {
    const response = await apiClient.post(`${BASE_URL}/nodes/`, nodeData);
    return { message: response.data.message, status: response.status };
  } catch (e: any) {
    if (e.response.status === 409) {
      return { message: "Node already exists", status: e.response.status };
    }
    return { message: e.message, status: e.response.status };
  }
};

export const updateNode = async (
  nodeId: number,
  updatedNodeData: {
    name?: string;
    siteId?: number;
    alias?: string | null;
    isActive?: boolean;
  }
): Promise<{ message: string; status: number }> => {
  try {
    const response = await apiClient.put(
      `${BASE_URL}/nodes/${nodeId}`,
      updatedNodeData
    );

    return { message: response.data.message, status: response.status };
  } catch (e: any) {
    if (e.response.status === 409) {
      return { message: "Node already exists", status: e.response.status };
    }
    return { message: e.message, status: e.response.status };
  }
};

export const fetchUsersByOrganizationId = async (organizationId: number) => {
  try {
    const response = await apiClient.get(
      `${BASE_URL}/users/organization/${organizationId}`
    );
    return response.data;
  } catch (e) {
    console.error("Error while getting Users data: ", e);
    return null;
  }
};

export const createUser = async (userData: {
  userName: string;
  phoneNumber: number;
  organizationId: number;
  role: string;
  email: string;
  password: string;
  isActive: boolean;
}): Promise<{ createdUser?: any; message: string; status: number }> => {
  try {
    const response = await apiClient.post(
      `${BASE_URL}/users/register`,
      userData
    );
    return {
      createdUser: response.data.user,
      message: response.data.message,
      status: response.status,
    };
  } catch (e: any) {
    return { message: e.response.data.message, status: e.response.status };
  }
};

export const updateUser = async (
  userId: number,
  userData: {
    userName?: string;
    phoneNumber?: number;
    organizationId?: number;
    role?: string;
    email?: string;
    isActive?: boolean;
  }
) => {
  try {
    const response = await apiClient.put(
      `${BASE_URL}/users/${userId}`,
      userData
    );

    // Return the message from the response
    return response.data.message;
  } catch (e: any) {
    if (e.response) {
      // If there is a response from the server
      if (e.response.status === 500) {
        message.error("Server error. Please try again."); // Show error message
      } else {
        console.error("Error while updating user:", e.response.data);
      }
    } else if (e.request) {
      console.error("No response received:", e.request);
    } else {
      console.error("Error during setup:", e.message);
    }

    return null; // Return null if there was an error
  }
};

export const changeUserPassword = async (
  userId: number,
  oldPassword: string,
  password: string
) => {
  try {
    const response = await apiClient.put(`${BASE_URL}/users/${userId}`, {
      password, // Ensure this is the new password
      oldPassword, // Ensure this is the old password
    });
    return response.data;
  } catch (e) {
    console.error("Error while updating password: ", e);
    return null;
  }
};

export const createUserSite = async (userSiteData: {
  siteId: number;
  userId: number;
}) => {
  try {
    const response = await apiClient.post(
      `${BASE_URL}/user-sites`,
      userSiteData
    );
    return response.data; // return the entire response including the message and userSite
  } catch (err) {
    console.log("Error while creating UserSite:", err);
    throw err; // rethrow the error to handle it where the method is called
  }
};

export const createUserNode = async (userNodeData: {
  nodeId: number;
  userId: number;
}) => {
  try {
    const response = await apiClient.post(
      `${BASE_URL}/user-nodes`,
      userNodeData
    );
    return response.data; // return the entire response including the message and userNode
  } catch (err) {
    console.log("Error while creating UserNode:", err);
    throw err; // rethrow the error to handle it where the method is called
  }
};

export const fetchUserSitesByUserId = async (userId: number) => {
  try {
    const response = await apiClient.get(
      `${BASE_URL}/user-sites/user/${userId}`
    );
    return response.data; // return the response data (list of user sites)
  } catch (e) {
    console.error("Error while fetching User Sites data: ", e);
    return null; // return null or handle the error as needed
  }
};

export const fetchUserNodesByUserIdAndSiteId = async (
  userId: number,
  siteId: number
) => {
  try {
    const response = await apiClient.get(
      `${BASE_URL}/user-nodes/user/${userId}/site/${siteId}`
    );
    return response.data; // return the response data (list of user nodes)
  } catch (e) {
    console.error("Error while fetching User Nodes data: ", e);
    return null; // return null or handle the error as needed
  }
};

export const deleteUserSite = async (userId: number) => {
  try {
    const response = await apiClient.delete(
      `${BASE_URL}/user-sites/user/${userId}`
    );

    return response.data.message;
  } catch (e) {
    console.error("Error while deleting user site: ", e);
    return null;
  }
};

export const deleteUserNode = async (userId: number) => {
  try {
    const response = await apiClient.delete(
      `${BASE_URL}/user-nodes/user/${userId}`
    );

    return response.data.message;
  } catch (e) {
    console.error("Error while deleting user node: ", e);
    return null;
  }
};
