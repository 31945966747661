import { useState, useEffect } from 'react';
import { Button, message, Table, Tooltip, Typography } from 'antd';
import { PieChartOutlined } from "@ant-design/icons";
import Navbar from '../Navbar';
import ExportModal from './ExportModal';
import Sidebar from '../Sidebar/Sidebar';
import CollectionsCollapsedListing from './CollectionsCollapsedListing';
import { useNavigate } from 'react-router-dom';
import { getCollections, fetchNodes, fetchSites } from '../../Services'; // Import the service function
import { ManualSpinnerLoader } from '../../ManualSpinnerLoader';
import { Utilities } from '../../Utilities';

const CollectionsListing = () => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const utils = new Utilities();

  const initialStorageData = {
    count: null,
    jobRecId: null,
    nodeName: null,
    siteName: null,
    dropDownKey: null,
    startTime: null,
    endTime: null,
    maskStartTime: null,
    maskEndTime: null,
    threshold: null,
    bandwidth: null,
    sdrGain: null,
    frequency: null,
    sampleRate: null,
    operations: [],
    descritionDetails: {}

  };

  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [expandedType, setExpandedType] = useState<string | null>(null);
  const [tableKey, setTableKey] = useState(0);
  const [collectionData, setCollectionData] = useState<any[]>([]);
  const [storageData, setStorageData] = useState(initialStorageData);
  const [nodeAliases, setNodeAliases] = useState<any[]>([]); // State to store node aliases from local storage
  const [activePieChartKey, setActivePieChartKey] = useState<number | null>(null);
  const [activeRecId, setActiveRecId] = useState<number | null>(null);
  const [sites, setSites] = useState<[]>([]);
  const [globalNodes, setGlobalNodes] = useState<string[]>([]);
  const [globalSite, setGlobalSite] = useState<string | null>(null);
  const [globalStartTime, setGlobalStartTime] = useState<string | null>(null);
  const [globalStopTime, setGlobalStopTime] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSiteChange = (siteName: string | null) => {
    setGlobalSite(siteName);
  };

  const handleNodesChange = (nodes: string[]) => {
    setGlobalNodes(nodes)
  };

  const handleStartTime = (time: string | null) => {
    setGlobalStartTime(time)
  };

  const handleStopTime = (time: string | null) => {
    setGlobalStopTime(time)
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch nodes and sites together
        const [nodes, fetchedSites] = await Promise.all([fetchNodes(), fetchSites()]);
        setNodeAliases(nodes);
        setSites(fetchedSites);

        // Store in local storage if needed
        localStorage.setItem('nodes', JSON.stringify(nodes));
        localStorage.setItem('sites', JSON.stringify(fetchedSites));
      } catch (error) {
        console.error("Error fetching nodes and sites:", error);
      }
    };
    localStorage.removeItem('StorageData');
    localStorage.removeItem('InitialReportKey');
    utils.deleteDatabase();
    fetchData();
  }, []); // Only run once on mount


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Make sure both nodeAliases and sites are available
        if (nodeAliases.length > 0 && sites.length > 0) {
          const startDateTimeStr = localStorage.getItem('startDateTime') ? JSON.parse(localStorage.getItem('startDateTime') ?? "") : null;
          const stopDateTimeStr = localStorage.getItem('stopDateTime') ? JSON.parse(localStorage.getItem('stopDateTime') ?? "") : null;

          const nodesnamee = localStorage.getItem('selectedNodes');
          const selectedNodes = nodesnamee ? JSON.parse(nodesnamee) : [];
          const sitesName = localStorage.getItem('selectedSite');
          const selectedSite = sitesName ? JSON.parse(sitesName) : "";

          // Fetch collection data
          const filteredData = await filterDataByDateRange(startDateTimeStr, stopDateTimeStr, selectedNodes, selectedSite);

          const statusBasedFilteredData = filteredData.filter((item: any) => item.status !== "failed");

          setCollectionData(statusBasedFilteredData);
          setIsLoading(false);
        }
        else {
          setIsLoading(false);
          //message.error("No Data Found");
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchData();
  }, [nodeAliases, sites, globalSite, globalNodes, globalStartTime, globalStopTime]); // Only run when nodeAliases and sites have been set


  // Filter data by date range
  const filterDataByDateRange = async (startDateTimeStr: string, stopDateTimeStr: string, selectedNodes: string[], selectedSite: string) => {
    const result = await getCollections(500, 0);
    if (result) {
      const { status, message: collectionsDataResponseMessage, data } = result;
      if (status !== 200) {
        setIsLoading(false);
        message.error(collectionsDataResponseMessage, 3);
        return;
      }

      const filteredData = data?.records.filter((record: any) => {

        const collectStart = new Date(record.job.collect_start)
        const collectStop = new Date(record.job.collect_end)
        const startTime: any = startDateTimeStr ? new Date(startDateTimeStr) : null
        const stopTime: any = stopDateTimeStr ? new Date(stopDateTimeStr) : null

        const isWithinRange = (collectStart <= stopTime && collectStop >= startTime);


        const matchingAlias = nodeAliases.find((node: any) => node.name === record.node.name);
        const matchingSite: any = sites.find((site: any) => site.id === matchingAlias?.siteId);
        const displaySiteName = matchingSite ? matchingSite.name : "Unknown";
        const nodeNames: any = nodeAliases.filter(node => selectedNodes.includes(node.alias || node.name));

        if (selectedSite.trim() !== "" && selectedNodes.length > 0 && (startTime !== null && stopTime !== null)) {
          return (
            selectedSite === displaySiteName &&
            (nodeNames.find((key: any) => key.name === record.node.name)) &&
            isWithinRange
          );
        }
        else if (selectedSite.trim() !== "" && selectedNodes.length > 0) {
          return (
            selectedSite === displaySiteName &&
            (nodeNames.find((key: any) => key.name === record.node.name))
          );
        }
        else if (selectedNodes.length > 0) {
          return (
            nodeNames.find((key: any) => key.name === record.node.name)
          );
        }
        else if (selectedSite.trim() !== "") {
          return (
            selectedSite === displaySiteName
          );
        }
        else if (startTime !== null && stopTime !== null) {
          return (
            isWithinRange
          );
        }
        else {
          return data;
        }


      }).map((record: any, index: number) => {
        const matchingAlias = nodeAliases.find((node: any) => node.name === record.node.name);
        const displayNodeName = matchingAlias && matchingAlias?.alias?.trim() ? matchingAlias.alias : record.node.name;

        const matchingSite: any = sites.find((site: any) => site.id === matchingAlias?.siteId);
        const displaySiteName = matchingSite ? matchingSite.name : "Unknown";

        const splittedStatusArray = record.job.status.split("~");
        const lastIndex = splittedStatusArray.length - 1;
        const lastStatus = splittedStatusArray[lastIndex].toLowerCase();


        return {
          key: index,
          count: data.count,
          status: lastStatus,
          siteName: displaySiteName,
          recId: record.job.recid,
          packName: record.job.pack_name,
          nodeName: displayNodeName,
          startDate: formatDateTime(record.job.collect_start).formattedDate,
          startTime: formatDateTime(record.job.collect_start).formattedTime,
          stopDate: formatDateTime(record.job.collect_end).formattedDate,
          stopTime: formatDateTime(record.job.collect_end).formattedTime,
          centerFrequency: record.job.center_frequency,
          bandwidth: record.job.usable_bw,
          descriptionDetails: {
            recId: record.job.recid,
            collectStart: record.job.collect_start,
            collectStop: record.job.collect_end,
            nodeRecId: record.node.recid,
            centerFrequency: record.job.center_frequency,
            bandwidth: record.job.usable_bw,
            sampleRate: record.job.sample_rate,
            sdrUseAgc: record.job.sdr_use_agc,
            sdrGain: record.job.sdr_gain,
            psd: record.job.pack_name.includes("PSD") ? "Y" : "N",
            chop: record.job.chop_enabled,
            lmrDecode: "N",
            lteDecode: "N",
            locationData: "Y",
            rdsa: record.job.rdsa !== null && record.job.rdsa !== undefined ? record.job.rdsa : "null",
            fdsa: record.job.fdsa !== null && record.job.fdsa !== undefined ? record.job.fdsa : "null",
            lna: record.job.lna !== null && record.job.lna !== undefined ? record.job.lna : "null",
            receiverNumber: record.sdr.name
          },
        };
      });

      return filteredData;
    }

  };

  const onReportButtonClick = () => {
    const stringifiedStorageData = JSON.stringify(storageData);
    localStorage.setItem("StorageData", stringifiedStorageData);
    navigate('/reports-dashboard');
  };

  const onDisplayButtonClick = () => {
    const stringifiedStorageData = JSON.stringify(storageData);
    localStorage.setItem("StorageData", stringifiedStorageData);
    navigate('/displays')
  };


  const onExportButtonClick = () => {
    const stringifiedStorageData = JSON.stringify(storageData);
    localStorage.setItem("StorageData", stringifiedStorageData);
    setIsExportModalVisible(true);
  };

  const formatDateTime = (datetime: any) => {
    const dateObj = new Date(datetime);
    const formattedDate = `${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}/${String(dateObj.getUTCDate()).padStart(2, '0')}/${dateObj.getUTCFullYear()}`;
    const formattedTime = `${String(dateObj.getUTCHours()).padStart(2, '0')}:${String(dateObj.getUTCMinutes()).padStart(2, '0')}:${String(dateObj.getUTCSeconds()).padStart(2, '0')}`;

    return { formattedDate, formattedTime };
  };

  const handleExpandIconClick = (recordKey: number) => {
    if (expandedType === 'DescriptionDetails' && expandedRowKeys.includes(recordKey)) {
      setExpandedRowKeys([]);
      setExpandedType(null);
      setActivePieChartKey(null);
    } else {
      setExpandedRowKeys([recordKey]);
      setExpandedType('DescriptionDetails');
      setActivePieChartKey(null);
    }
  };


  const handleTimeSliceClick = (recordKey: any) => {
    setActiveRecId(recordKey.recId);
    if (expandedType === 'TimeSliceDetails' && expandedRowKeys.includes(recordKey.key) && activePieChartKey === recordKey.key) {
      // If the clicked icon is already active, deactivate it
      setActivePieChartKey(null);
      setExpandedRowKeys([]);
      setExpandedType(null);
    } else {
      // Activate the clicked icon and deactivate any previously active one
      setActivePieChartKey(recordKey.key);
      setExpandedRowKeys([recordKey.key]);
      setExpandedType('TimeSliceDetails');
    }
  };


  const getUniqueValues = (key: string) => {
    switch (key) {
      case "siteName":
      case "nodeName":
      case "status":
        return Array.from(new Set(collectionData.map((item: any) => item[key])))
          .sort((a, b) => { return a.localeCompare(b) })
          .map((record: string) => ({
            text: record,
            value: record
          }));

      case "recId":
        return Array.from(new Set(collectionData.map((item: any) => item[key])))
          .sort((a, b) => { return b - a })
          .map((record: number) => ({
            text: record,
            value: record
          }));

      case "startDate":
      case "stopDate":
        return Array.from(new Set(collectionData.map((item: any) => item[key])))
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
          .map((record: string) => ({
            text: record,
            value: record
          }));

      case "startTime":
      case "stopTime":
        return Array.from(new Set(collectionData.map((item: any) => item[key])))
          .sort((a, b) => { return b.localeCompare(a) })
          .map((record: string) => ({
            text: record,
            value: record
          }));

      case "centerFrequency":
        return Array.from(new Set(collectionData.map((item: any) => item[key])))
          .sort((a, b) => { return a - b })
          .map((record: number) => ({
            text: (record / 1000000).toFixed(4),
            value: (record / 1000000).toFixed(4)
          }));


      default:
        break;
    }
  };

  const handleResetFilters = () => {
    setExpandedRowKeys([]);
    setExpandedType(null);
    setTableKey((prevKey) => prevKey + 1);
  };

  const customColumns: any = [
    {
      title: "",
      render: (_: any, record: any) => (
        <span onClick={() => handleTimeSliceClick(record)}>
          <PieChartOutlined
            style={{
              marginRight: '8px',
              verticalAlign: 'middle',
              cursor: 'pointer',
              backgroundColor: activePieChartKey === record.key ? 'blue' : 'transparent',
              padding: '5px',
              fontSize: '20px',
              borderRadius: '4px'
            }}
          />
        </span>
      ),
      width: 160
    },
    {
      title: "REC ID",
      dataIndex: "recId",
      render: (_: any, record: any) => (<span>{record.recId}</span>),
      filters: getUniqueValues("recId"),
      onFilter: (value: any, record: any) => record.recId === value,
      filterSearch: true,
      width: 160
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (_: any, record: any) => (<span>{record.status}</span>),
      filters: getUniqueValues("status"),
      onFilter: (value: any, record: any) => record.status === value,
      filterSearch: true,
      width: 160
    },
    {
      title: "SITE NAME",
      dataIndex: "siteName",
      render: (_: any, record: any) => (<span>{record.siteName}</span>),
      filters: getUniqueValues("siteName"),
      onFilter: (value: any, record: any) => record.siteName === value,
      filterSearch: true,
      width: 160
    },
    {
      title: "NODE NAME",
      dataIndex: "nodeName",
      render: (_: any, record: any) => (<span>{record.nodeName}</span>),
      filters: getUniqueValues("nodeName"),
      onFilter: (value: any, record: any) => record.nodeName === value,
      filterSearch: true,
      width: 160
    },
    {
      title: (
        <Tooltip title="MM/DD/YYYY">
          <span>START DATE</span>
        </Tooltip>
      ),
      dataIndex: "startDate",
      render: (_: any, record: any) => (<span>{record.startDate}</span>),
      filters: getUniqueValues("startDate"),
      onFilter: (value: any, record: any) => record.startDate === value,
      filterSearch: true,
      width: 160
    },
    {
      title: "START TIME (UTC)",
      dataIndex: "startTime ",
      render: (_: any, record: any) => (<span>{record.startTime}</span>),
      filters: getUniqueValues("startTime"),
      onFilter: (value: any, record: any) => record.startTime === value,
      filterSearch: true,
      width: 180
    },
    {
      title: (
        <Tooltip title="MM/DD/YYYY">
          <span>STOP DATE</span>
        </Tooltip>
      ),
      dataIndex: "stopDate",
      render: (_: any, record: any) => (<span>{record.stopDate}</span>),
      filters: getUniqueValues("stopDate"),
      onFilter: (value: any, record: any) => record.stopDate === value,
      filterSearch: true,
      width: 160
    },
    {
      title: "STOP TIME (UTC)",
      dataIndex: "stopTime",
      render: (_: any, record: any) => (<span>{record.stopTime}</span>),
      filters: getUniqueValues("stopTime"),
      onFilter: (value: any, record: any) => record.stopTime === value,
      filterSearch: true,
      width: 160
    },
    {
      title: "CENTER FREQ (MHz)",
      dataIndex: "centerFrequency",
      render: (_: any, record: any) => (<span>{(record.centerFrequency / 1000000).toFixed(4)}</span>),
      filters: getUniqueValues("centerFrequency"),
      onFilter: (value: any, record: any) => (record.centerFrequency / 1000000).toFixed(4) === value,
      filterSearch: true,
      width: 180
    }
  ];

  return (
    <>
      <div className='h-fit'>
        <Navbar page="collections" />
      </div>
      <div className='min-h-[90vh] h-fit flex'>
        <Sidebar
          handleSiteChange={handleSiteChange}
          handleNodesChange={handleNodesChange}
          handleStartTime={handleStartTime}
          handleStopTime={handleStopTime}
        />
        <div className='min-h-[90vh] h-fit flex flex-col justify-between items-end p-4'>
          {isLoading ? <ManualSpinnerLoader componentName={"CollectionsListing"} /> :

            <Table
              className='w-full custom-table'
              title={() => (
                <div className="flex flex-row justify-between items-center">
                  <Button
                    className="w-fit"
                    size="middle"
                    onClick={handleResetFilters}
                  >
                    Reset filters and sorters
                  </Button>
                  <Title className="flex-grow text-center m-0" level={3} >
                    COLLECTIONS
                  </Title>

                  <Button
                    className="w-fit mr-3"
                    size="middle"
                    onClick={onDisplayButtonClick}
                    disabled={storageData?.dropDownKey !== "psd"}

                  >
                    Displays
                  </Button>

                  <Button
                    className="w-fit mr-2"
                    size="middle"
                    onClick={onReportButtonClick}
                    disabled={storageData?.dropDownKey !== "chop"}
                  >
                    Reports
                  </Button>

                  <Button
                    className="w-fit "
                    size="middle"
                    onClick={onExportButtonClick}
                    disabled={storageData?.dropDownKey === null}
                  >
                    Export
                  </Button>
                </div>
              )}
              key={tableKey}
              dataSource={collectionData}
              columns={customColumns}
              expandable={{
                expandedRowKeys,
                onExpand: (expanded, record: any) => handleExpandIconClick(record.key),
                expandedRowRender: (record) => {
                  if (expandedType === 'DescriptionDetails') {
                    return <CollectionsCollapsedListing
                      type="DescriptionDetails"
                      record={record}
                    />;
                  } else if (expandedType === 'TimeSliceDetails') {
                    return <CollectionsCollapsedListing
                      type="TimeSliceDetails"
                      storageData={storageData}
                      setStorageData={setStorageData}
                      record={record}
                      selectedRecId={activeRecId}
                    />;
                  }
                  return null;
                }
              }}
              bordered={true}
              pagination={collectionData.length <= 10 ? false : { showSizeChanger: true }}
            />
          }

        </div>
      </div>
      <ExportModal
        isExportModalVisible={isExportModalVisible}
        setIsExportModalVisible={setIsExportModalVisible}
      />
    </>
  );
};

export default CollectionsListing;