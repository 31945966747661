import { useState, useEffect, useContext } from "react";
import {
  DatePicker,
  TimePicker,
  Checkbox,
  Select,
  Typography,
  Divider,
  Collapse,
  Button,
  message,
} from "antd";
import { SearchOutlined, PieChartOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getCollections,
  getJobCVChopData,
  getChopSliceInfo,
  fetchNodesBySiteId,
  fetchSites,
  fetchSitesByUserId,
  fetchNodesByUserIdAndSiteId,
} from "../../../Services"; // Import the service function
import Cookies from "js-cookie";
import { Utilities } from "../../../Utilities";
import { Radio } from "antd";
import { ManualSpinnerLoader } from "../../../ManualSpinnerLoader";
import { AuthContext } from "../../Login/AuthProvider";
import { Role } from "../../../enums";



const NewReportModal = ({
  setIsModalVisible,
}: {
  setIsModalVisible: (visible: boolean) => void;
}) => {
  const { Title, Text } = Typography;
  const { Option } = Select;
  const utils = new Utilities();
  const timeSettings = Cookies.get("TimeSettings");
  const navigate = useNavigate();
  const [selectedSites, setSelectedSites] = useState<any>();
  const [selectedTimeSlice, setSelectedTimeSlice] = useState<string | null>(
    null
  );
  const [selectedEndTime, setSelectedEndTime] = useState<string | null>(null);
  const [modalNewData, setModalNewData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [stopDate, setStopDate] = useState<any>(null);
  const [startTime, setStartTime] = useState<any>(null);
  const [stopTime, setStopTime] = useState<any>(null);
  const [selectedReportType, setSelectedReportType] = useState<string | null>(
    null
  );
  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);
  const [nodeAliases, setNodeAliases] = useState<any[]>([]);
  const [chopTimeSliceOptions, setChopTimeSliceOptions] = useState<any[]>([]);
  const [openDropdownKey, setOpenDropdownKey] = useState<string | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [selectedAdditionalSites, setSelectedAdditionalSites] = useState<
    string[]
  >([]);
  const [chopCheck, setChopCheck] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sites, setSites] = useState<any[]>([]);
  const [nodes, setNodes] = useState<any[]>([]);
  const [userRole, setUserRole] = useState<any>({});
  const [userId, setUserId] = useState<number | null>(null);
  const { auth } = useContext(AuthContext);

  // Handle the main site selection
  const handleSiteChange = async (e: any) => {
    const siteName = e.target.value;

    const selectedSite = sites.find(
      (s) => s.name.toLowerCase() === siteName.toLowerCase()
    );
    if (selectedSite) {
      setSelectedSites(siteName);
      setNodes([]);
      try {
        let nodesBySiteId: [];
        if (userRole === Role.SUPER_ADMIN) {
          nodesBySiteId = await fetchNodesBySiteId(selectedSite.id);
        } else {
          nodesBySiteId = await fetchNodesByUserIdAndSiteId(userId || 0, selectedSite.id);
        }

        if (Array.isArray(nodesBySiteId)) {
          const nodesWithAliases = nodesBySiteId
            .map((node: any) =>
              node.alias && node?.alias?.trim() !== "" ? node.alias : node.name
            )
            .filter(Boolean);
          setSelectedAdditionalSites(nodesWithAliases);
        } else {
          console.error("Expected an array of nodes but got:", nodes);
          setSelectedAdditionalSites([]);
        }
      } catch (error) {
        console.error("Error fetching nodes data:", error);
        setSelectedAdditionalSites([]);
      }
    }
  };

  // Handle the additional site selection
  const handleAdditionalSiteChange = (checkedValues: string[]) => {
    setNodes(checkedValues);
    setIsSearchEnabled(checkedValues.length > 0);
  };

  // Fetch node aliases from local storage , and sites and nodes

  const fetchSitesData = async (userId: number, role: string) => {
    try {
      if (role == Role.SUPER_ADMIN) {
        const siteData = await fetchSites();
        setSites(siteData);
      } else {
        setUserId(userId);
        const siteData = await fetchSitesByUserId(userId);
        setSites(siteData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {

    if (auth.token) {
      const decodedToken = utils.jwtDecode(auth.token);

      if (decodedToken?.id) {
        setUserRole(decodedToken?.role);
        fetchSitesData(decodedToken.id, decodedToken?.role);
      }
    }
    const storedNodes = JSON.parse(localStorage.getItem("nodes") || "[]");
    setNodeAliases(storedNodes);

  }, []);

  const handleIconClick = (key: string, record: any) => {
    setOpenDropdownKey((prevKey) => (prevKey === key ? null : key)); // Toggle the dropdown
    setSelectedRecord(record); // Set selected record correctly
    handleChopClick(record.recId);
  };

  const handleSearch = async () => {
    setIsSearchPerformed(true);

    if (startDate && stopDate && startTime && stopTime) {
      const startDateTime = new Date(
        `${startDate.format("YYYY-MM-DD")}T${startTime.format("HH:mm:ss")}Z`
      );
      const stopDateTime = new Date(
        `${stopDate.format("YYYY-MM-DD")}T${stopTime.format("HH:mm:ss")}Z`
      );
      setIsLoading(true);
      try {
        const result = await getCollections(500, 0);
        if (result) {
          const { status, message: collectionsDataResponseMessage, data } = result;
          if (status !== 200) {
            setIsLoading(false);
            message.error(collectionsDataResponseMessage, 3);
            return;
          }
          if (data && data.records) {
            const filteredData = data.records.filter((record: any) => {
              const collectStart = new Date(record.job.collect_start);
              const collectEnd = new Date(record.job.collect_end);

              const isWithinRange =
                collectStart <= stopDateTime && collectEnd >= startDateTime;
              const nodeNames = nodeAliases.filter((node) =>
                nodes.includes(node.alias || node.name)
              );
              return (
                isWithinRange &&
                nodeNames.find((key) => key.name === record.node.name)
              );
            });

            if (filteredData.length > 0) {
              const updatedRecords = filteredData.map(
                (record: any, index: number) => {
                  const matchingAlias = nodeAliases.find(
                    (node: any) => node.name === record.node.name
                  );
                  const displayNodeName = matchingAlias?.alias?.trim()
                    ? matchingAlias.alias
                    : record.node.name;

                  return {
                    key: index,
                    siteName: record.node.name,
                    nodeName: displayNodeName,
                    recId: record.job.recid,
                  };
                }
              );
              setModalNewData(updatedRecords);
              setIsLoading(false);
            } else {
              message.warning(
                "No data available for the exact date range and selected sites."
              );
              setModalNewData([]);
              setIsLoading(false);
            }
          }
        };

      } catch (error) {
        console.error("Error fetching or filtering data from API:", error);
        setIsLoading(false);
      }
    } else {
      message.warning(
        "Please select start date, stop date, start time, and stop time."
      );
      setIsLoading(false);
    }
  };

  const handleCreateReport = () => {
    if (selectedTimeSlice && selectedSites) {
      if (!selectedRecord) {
        message.warning("Please select a record to create the report.");
        return;
      }

      const matchingAlias = nodeAliases.find(
        (node: any) => node.name === selectedRecord.nodeName
      );
      const nodeNameToSend = matchingAlias?.alias?.trim()
        ? matchingAlias.alias
        : selectedRecord.nodeName;

      const tempStorageData = {
        jobRecId: selectedRecord.recId,
        startTime: selectedTimeSlice,
        endTime: selectedEndTime,
        nodeName: nodeNameToSend,
        siteName: selectedSites
      };

      localStorage.removeItem('StorageData');
      localStorage.removeItem('InitialReportKey');
      utils.deleteDatabase();
      const stringifiedStorageData = JSON.stringify(tempStorageData);
      localStorage.setItem("StorageData", stringifiedStorageData);
      navigate('/reports-dashboard');
      setIsModalVisible(false);
    } else {
      message.warning("Please select a report type and CHOP time slice.");
    }
  };

  const handleCancelReport = () => {
    setIsModalVisible(false);
  };

  const handleChopClick = async (recId: string) => {
    try {
      const chopData = await getJobCVChopData(parseInt(recId));
      const bandwidths = chopData.map((slice: any) => slice.width);
      const frequencies = chopData.map((slice: any) => slice.frequency);
      const response = await getChopSliceInfo(
        parseInt(recId),
        bandwidths,
        frequencies
      );
      setChopCheck("Y");

      if (response.start_time && response.end_time) {
        const startTime = new Date(response.start_time + "Z").toISOString();
        const endTime = new Date(response.end_time + "Z").toISOString();
        const timeSlices = utils
          .createTimeSlices(startTime, endTime)
          .map((time) => time.toISOString());
        setChopTimeSliceOptions(timeSlices);
      } else {
        console.error("Invalid start or end time:", response);
      }
    } catch (error) {
      console.error("Error fetching CHOP data:", error);
      setChopCheck("N");
      console.log(`REC ID: ${parseInt(recId)} has no CHOP Data`);
    }
  };

  const handleTime = (value: any, type: string) => {
    if (type === "chop") {
      const selectedTime = value;
      const endTime = utils.calculateEndTime(selectedTime, timeSettings);
      setSelectedTimeSlice(selectedTime);
      setSelectedEndTime(endTime ? endTime : null);
    }
  };

  const customItems = modalNewData.map((record, index) => {
    const key = (index + 1).toString();
    const isDropdownOpen = openDropdownKey === key;

    return {
      key: key,
      header: (
        <span onClick={() => handleIconClick(key, record)}>
          <PieChartOutlined // Pass record to the click handler
            style={{
              marginRight: "8px",
              verticalAlign: "middle",
              cursor: "pointer",
              backgroundColor: isDropdownOpen ? "blue" : "transparent",
              padding: "5px",
              fontSize: "15px",
            }}
          />
          {record.recId} - {record.nodeName}
        </span>
      ),
      children: isDropdownOpen ? (
        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
          <Select
            style={{ width: 330 }}
            allowClear={true}
            placeholder="CHOP"
            onChange={(e) => {
              if (e) {
                handleTime(e, "chop");
              }
            }}
            options={chopTimeSliceOptions.map((time: any, index: number) => ({
              label: `CHOP Time Slice ${index + 1}: ${time}`,
              value: time,
            }))}
            disabled={chopCheck === null || chopCheck !== "Y"}
          />
          <Select
            style={{ width: 140, marginLeft: "10px" }}
            allowClear={true}
            placeholder="LTE"
            disabled
          />
          <Select
            style={{ width: 140, marginLeft: "10px" }}
            allowClear={true}
            placeholder="LMR"
            disabled
          />
        </div>
      ) : null,
    };
  });

  return (
    <div
      style={{
        padding: 20,
        margin: "0 auto",
        backgroundColor: "#1e1e1e",
        color: "#fff",
        borderRadius: 10,
      }}
    >
      <Title level={4} style={{ color: "#fff" }}>
        Create New Report
      </Title>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "45%" }}>
          <Title level={5} style={{ color: "#fff" }}>
            Date/Time
          </Title>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 16,
            }}
          >
            <div style={{ width: "48%" }}>
              <Title level={5} style={{ color: "#fff" }}>
                Start Date
              </Title>
              <DatePicker
                style={{ width: "100%" }}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div style={{ width: "48%" }}>
              <Title level={5} style={{ color: "#fff" }}>
                Stop Date
              </Title>
              <DatePicker
                style={{ width: "100%" }}
                onChange={(date) => setStopDate(date)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 16,
            }}
          >
            <div style={{ width: "48%" }}>
              <Title level={5} style={{ color: "#fff" }}>
                Start Time
              </Title>
              <TimePicker
                style={{ width: "100%" }}
                onChange={(time) => setStartTime(time)}
              />
            </div>
            <div style={{ width: "48%" }}>
              <Title level={5} style={{ color: "#fff" }}>
                Stop Time
              </Title>
              <TimePicker
                style={{ width: "100%" }}
                onChange={(time) => setStopTime(time)}
              />
            </div>
          </div>
          <Divider style={{ backgroundColor: "#444" }} />
          <div>
            <Title level={5} style={{ color: "#fff" }}>
              Select Sites
            </Title>
            <Radio.Group
              className="w-full"
              value={selectedSites}
              onChange={handleSiteChange}
            >
              {sites.map((site) => (
                <div key={site.id}>
                  <Radio value={site.name}>{site.name}</Radio>
                  {selectedSites === site.name && (
                    <Checkbox.Group
                      className="flex flex-col gap-1 px-6 py-2"
                      value={nodes}
                      onChange={handleAdditionalSiteChange}
                    >
                      {selectedAdditionalSites.map((node) => (
                        <Checkbox key={node} value={node}>
                          {node}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  )}
                </div>
              ))}
            </Radio.Group>
          </div>
          <div style={{ marginTop: 36 }}>
            <Button
              type="primary"
              onClick={handleSearch}
              icon={<SearchOutlined />}
              disabled={
                startDate === null ||
                stopDate === null ||
                startTime === null ||
                stopTime === null ||
                !isSearchEnabled
              }
            >
              Search for REC ID
            </Button>
          </div>
        </div>
        {isLoading ? (
          <ManualSpinnerLoader componentName={"CollectionsListing"} />
        ) : (
          <div style={{ width: "45%", paddingRight: "2%" }}>
            <Text style={{ color: "#fff" }}>
              REC ID - Nodename{" "}
            </Text>
            <div
              style={{ width: "115%", maxHeight: "300px", overflowY: "auto" }}
            >
              {isSearchPerformed && (
                <Collapse
                  activeKey={openDropdownKey ?? undefined}
                  expandIcon={() => null}
                  expandIconPosition="right"
                >
                  {customItems.map((item) => (
                    <Collapse.Panel key={item.key} header={item.header}>
                      {item.children}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "150px",
              }}
            >
              <Button type="default" onClick={handleCancelReport}>
                Cancel
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                type="primary"
                onClick={handleCreateReport}
              >
                Reports
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewReportModal;
