import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NoPage from "./Components/NoPage";
import CollectionsListing from "./Components/Collections/CollectionsListing";
import Settings from "./Components/Settings/Settings";
import Displays from "./Components/Displays";
import { SpinnerLoader } from "./SpinnerLoader";
import LoginScreen from "./Components/Login/LoginScreen";
import { AuthProvider } from "./Components/Login/AuthProvider";
import ProtectedRoute from "./Components/Login/ProtectedRoute";
import Organizations from "./Components/Organization/Organization";
import ManageSites from "./Components/Organization/ManageSites";
import ManageNodes from "./Components/Organization/ManageNodes";
import ManageUsers from "./Components/Organization/ManageUsers";
import Utilization from "./Components/Utilization/Utilization";
import ReportsDashboard from "./Components/Reports/ReportsDashboard";
import ReportsDisplay from "./Components/Reports/ReportsDisplay";

const router = createBrowserRouter([
  {
    index: true,
    element: <LoginScreen />,
  },

  {
    path: "/collections",
    element: <ProtectedRoute element={<CollectionsListing />} />,
  },
  {
    path: "/utilization",
    element: <ProtectedRoute element={<Utilization />} />,
  },
  {
    path: "/displays",
    element: <ProtectedRoute element={<Displays />} />,
  },
  {
    path: "/reports",
    element: <ProtectedRoute element={<ReportsDisplay />} />,
  },
  {
    path: "/settings",
    element: <ProtectedRoute element={<Settings />} />,
  },
  {
    path: "/organizations",
    element: <ProtectedRoute element={<Organizations />} />,
  },

  {
    path: "/managesites",
    element: <ProtectedRoute element={<ManageSites />} />,
  },

  {
    path: "/manage-nodes",
    element: <ProtectedRoute element={<ManageNodes />} />,
  },

  {
    path: "/manage-users",
    element: <ProtectedRoute element={<ManageUsers />} />,
  },

  {
    path: "/reports-dashboard",
    element: <ProtectedRoute element={<ReportsDashboard />} />,
  },

  {
    path: "*",
    element: <NoPage />,
  },
]);

const App = () => {
  return (
    <AuthProvider>
      <div>
        <SpinnerLoader />
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  );
};

export default App;
